import ITextValue from 'types/textValue';

export const LroiAttributionOptions: ITextValue[] = [
  // Converting the value to seconds by {days * seconds * minutes * hours}
  {
    text: '1 Day',
    value: `${1 * 60 * 60 * 24}`,
  },
  {
    text: '7 Days',
    value: `${7 * 60 * 60 * 24}`,
  },
  {
    text: '14 Days',
    value: `${14 * 60 * 60 * 24}`,
  },
  {
    text: '30 Days',
    value: `${30 * 60 * 60 * 24}`,
  },
  {
    text: '60 Days',
    value: `${60 * 60 * 60 * 24}`,
  },
  {
    text: '90 Days',
    value: `${90 * 60 * 60 * 24}`,
  },
  {
    text: '180 Days',
    value: `${180 * 60 * 60 * 24}`,
  },
  {
    text: '240 Days',
    value: `${240 * 60 * 60 * 24}`,
  },
];
