import {
  emptyConfirmationDialog,
  emptyLastValidReport,
  emptyModalDialog,
  newBaseReport,
} from 'domains/reports/initializers';
import IReport, {
  AttributionFilterType,
  AttributionFilterTypes,
  ISetSorting,
} from 'domains/reports/types';
import { assign, cloneDeep, find, flow, get, omit, set } from 'lodash/fp';
import ActionType from 'store/actions/types';
import { Action } from 'types/action';
import { IReportSorting, Mode, Modes, reportSortingOrder } from 'types/query';
import ITextValue from 'types/textValue';
import { IQueryFilters, RuleFilter, UndigestedFilter } from 'types/filter';
import { initialDomainsDateRanges } from '../businessData';

/**
 * before initialization use non legacy initial date
 */
export const initialState: IReport = newBaseReport(initialDomainsDateRanges);

export const getConfirmationDialogType = get(
  'notPersistedProps.confirmationDialog.confirmationDialogType',
);
export const getDatasets = get('query.datasets');
export const getDimensions = get('query.group');
export const getInterval = get('query.interval');
export const getMetrics = get('query.select');
export const getNotPersistedProps = get('notPersistedProps');
export const getQuery = get('report.query');
export const getQueryDimensions = get('query.group');
export const getQueryFilters = get('query.filters.children');

export const getExposureAdsQueryFilters = flow(
  getQueryFilters,
  find({ name: 'exposures_ads' }),
  get('children'),
);

export const getQueryMetrics = get('query.select');
export const getQueryMode = get('query.mode');
export const getQueryScheduleDatasets = get('query.datasets');
export const getQueryGenericEventsDatasets = get(
  'query.generic_events_datasets',
);
export const getQuerySorting = get('query.sorting');
export const getRealQueryMode = get('notPersistedProps.mode');
export const getReport = get('report');
export const getUpdatedReport = get('updatedReport');
export const getReportHasChanged = get('notPersistedProps.reportHasChanged');

export const omitCumulativeGroup = omit('query.cumulative_group');

export const setAccordionQueryOpen = set(
  'notPersistedProps.isAccordionQueryOpen',
);
export const setAccordionResultsOpen = set(
  'notPersistedProps.isAccordionResultsOpen',
);
export const setAccordionSettingsOpen = set(
  'notPersistedProps.isAccordionSettingsOpen',
);
export const setAggregationMethod = set('aggregation_method');
export const setAttributionWindow = set('query.attribution_window.end');
export const setBaseReportFullyLoaded = set(
  'notPersistedProps.isBaseReportFullyLoaded',
);
const setIsDownloadingPresentation = set(
  'notPersistedProps.isDownloadingPresentation',
);
const setPersistedReport = set('persistedReport');
export const setBaseReportSaved = set('notPersistedProps.isBaseReportSaved');

export const setIsReportInvalid = set('notPersistedProps.isReportInvalid');

export const toggleIsModalOpen = set('notPersistedProps.isModalOpen');
export const setConfirmationDialogType = set(
  'notPersistedProps.confirmationDialog.confirmationDialogType',
);
export const setReportDynamicBreakouts = set(
  'notPersistedProps.dynamicBreakouts',
);
export const setReportPerformanceMetricGroups = set(
  'notPersistedProps.complexOutcomes.performanceMetricGroups',
);
export const setReportConversionType = set(
  'notPersistedProps.complexOutcomes.conversionType',
);
export const setGenericConversionsMinDate = set(
  'notPersistedProps.complexOutcomes.minDate',
);
export const setGenericConversionsMaxDate = set(
  'notPersistedProps.complexOutcomes.maxDate',
);

export const setEqMetric = set('use_equivalized_metrics');
export const setQueryConversionType = (
  report: IReport,
  payload?: string,
): IReport => {
  if (payload) {
    return set('query.conversion_type', payload, report);
  }
  return omit('query.conversion_type', report) as IReport;
};
export const setCumulativeGroup = (payload: string, report: IReport): IReport =>
  set('query.cumulative_group', payload ? payload : '', report);
export const setDimensions = set('query.group');
export const setInterval = set('query.interval');
export const setMaxDate = set('notPersistedProps.maxDate');
export const setMetricType = set('notPersistedProps.metricType');
export const setMetrics = set('query.select');
export const setMinDate = set('notPersistedProps.minDate');
export const setNotPersistedProps = set('notPersistedProps');
export const setTableVisible = set('query.tableVisible');
export const setChartVisible = set('query.chartVisible');
export const setQueryDatasets = set('query.datasets');
export const setQueryDaypartDatasetId = set('query.daypart_dataset_id');
export const setCrosswalkCombinationId = set(
  'query.genpop_crosswalk_combination',
);
export const setQueryGenericEventsDatasets = set(
  'query.generic_events_datasets',
);
export const setQueryFilterEndDate = set('query.filters.children[0].end');
export const setQueryFilterStartDate = set('query.filters.children[0].start');
export const setQueryFilterRelativeDateOffset = (
  payload: string,
  report: IReport,
): IReport => {
  const returned = payload
    ? (set(
        'query.filters.children[0].relativeDateOffset',
        payload,
        report,
      ) as IReport)
    : (omit('query.filters.children[0].relativeDateOffset', report) as IReport);
  return returned;
};
export const setQueryFilters = set('query.filters');
export const setQueryFiltersChildren = set('query.filters.children');
export const setBreakoutCategoriesFilters = (
  filters: RuleFilter[],
  report: IReport,
): IReport => set('query.breakout_categories.children', filters, report);
export const setBreakoutCategoriesStartDate = set(
  'query.breakout_categories.dates.start',
);
export const setBreakoutCategoriesEndDate = set(
  'query.breakout_categories.dates.end',
);
export const setQueryLevel = set('query.level');
export const setQueryMode = set('query.mode');
export const setQuerySampleFactor = set('query.sample_factor');
export const setQueryScheduleDatasets = set('query.datasets');
export const setQuerySorting = set('query.sorting');
export const setQueryTargets = set('query.targets');
export const setQueryTimeFrame = set('query.time_frame');
export const setQueryTimeZone = set('query.time_zone');
export const setQueryWeight = set('query.weight');
export const setQueryIncludeTargetBreakdown = set(
  'query.includeTargetBreakdown',
);
export const setQueryPerformanceMetricGroups = set(
  'query.performance_metric_groups',
);
export const setRealQueryMode = set('notPersistedProps.mode');
export const setReport = set('report');
export const setReportName = set('name');
export const setReportConfirmationDialog = set(
  'notPersistedProps.confirmationDialog',
);
export const setReportHasChanged = set('notPersistedProps.reportHasChanged');
export const setReportLoaded = set('notPersistedProps.isBaseReportLoaded');
export const setReportModalDialog = set('notPersistedProps.modalDialog');
export const setReportRulesetEvents = set('notPersistedProps.rulesetEvents');
export const setSavedReport = set('savedReport');
export const setSortField = set('sort_field');
export const setSortOrder = set('sort_order');

export const setAttributionReportFilterDate = (
  payload: string,
  report: IReport,
  accessor: string,
  type: AttributionFilterTypes,
): IReport => {
  const newReport = cloneDeep(report);
  const exposureChildren = (
    (newReport.query?.filters?.children as IQueryFilters[]) ?? []
  ).find((queryFilter: IQueryFilters) => queryFilter.name === type);
  if (!exposureChildren?.children) return newReport;

  const found =
    exposureChildren?.children.length &&
    (exposureChildren?.children as RuleFilter[]).find(
      (ruleFilter: RuleFilter) => ruleFilter.id === 'FLIGHT_DATES',
    );
  if (payload) {
    (found as unknown as Record<string, string>)[accessor] = payload;
  } else {
    delete (found as unknown as Record<string, string>)[accessor];
  }
  return newReport;
};

export const setExposureFlightDate = (
  payload: string,
  report: IReport,
  accessor: string,
): IReport =>
  setAttributionReportFilterDate(
    payload,
    report,
    accessor,
    AttributionFilterType.adDetails,
  );

export const setOutcomeViewershipDate = (
  payload: string,
  report: IReport,
  accessor: string,
): IReport =>
  setAttributionReportFilterDate(
    payload,
    report,
    accessor,
    AttributionFilterType.viewership,
  );

export const setOutcomeBrandLiftDate = (
  payload: string,
  report: IReport,
  accessor: string,
): IReport =>
  setAttributionReportFilterDate(
    payload,
    report,
    accessor,
    AttributionFilterType.brandLift,
  );

export const setAdhocDate = (
  payload: string,
  report: IReport,
  accessor: string,
): IReport =>
  setAttributionReportFilterDate(
    payload,
    report,
    accessor,
    AttributionFilterType.adhoc,
  );

export const setExposureFlightDateStart = (
  payload: string,
  report: IReport,
): IReport => setExposureFlightDate(payload, report, 'start');
export const setExposureFlightDateEnd = (
  payload: string,
  report: IReport,
): IReport => setExposureFlightDate(payload, report, 'end');
export const setExposureFlightDateRelativeOffset = (
  payload: string,
  report: IReport,
): IReport => setExposureFlightDate(payload, report, 'relativeDateOffset');
export const setOutcomeViewershipDateStart = (
  payload: string,
  report: IReport,
): IReport => setOutcomeViewershipDate(payload, report, 'start');
export const setOutcomeViewershipDateEnd = (
  payload: string,
  report: IReport,
): IReport => setOutcomeViewershipDate(payload, report, 'end');
export const setOutcomeBrandLiftDateStart = (
  payload: string,
  report: IReport,
): IReport => setOutcomeBrandLiftDate(payload, report, 'start');
export const setOutcomeViewershipRelativeDateOffset = (
  payload: string,
  report: IReport,
): IReport => setOutcomeViewershipDate(payload, report, 'relativeDateOffset');
export const setOutcomeBrandLiftDateEnd = (
  payload: string,
  report: IReport,
): IReport => setOutcomeBrandLiftDate(payload, report, 'end');
export const setAdhocDateStart = (payload: string, report: IReport): IReport =>
  setAdhocDate(payload, report, 'start');
export const setAdhocDateEnd = (payload: string, report: IReport): IReport =>
  setAdhocDate(payload, report, 'end');

const setQueryModeAndResetDatasets = (
  payload: Mode,
  report: IReport,
): IReport => {
  const filtersChildren = setFilterBrandCreativeEmptyForAds(report);

  return flow(
    setQueryDatasets([]),
    setQueryMode(payload),
    setQueryFiltersChildren(filtersChildren),
  )(report);
};

const resetCustomExposureSegmentRules = (report: IReport): IReport => {
  const filtersChildren = setFilterBrandCreativeEmptyForAds(report);
  return flow(setQueryFiltersChildren(filtersChildren))(report);
};

const setReportSorting = (
  { businessData, sorting }: ISetSorting,
  report: IReport,
): IReport => {
  const { order, field } = sorting;
  const { availableDimensions, availableIntervals, availableMetrics } =
    businessData;

  const availableOptions = [
    ...(availableMetrics as ITextValue[]),
    ...(availableDimensions as ITextValue[]),
    ...(availableIntervals as ITextValue[]),
  ];
  const selectedOption = find({ id: field }, availableOptions) as ITextValue;

  if (selectedOption) {
    const sortOrder = {
      id: order,
      name: reportSortingOrder[order],
    };
    const sortField = {
      id: selectedOption.value,
      name: selectedOption.text,
    };

    return flow(
      setSortOrder(sortOrder),
      setSortField(sortField),
      setQuerySorting(sorting),
    )(report);
  }

  return report;
};

const setRowLimit = set('rowLimit');
const setSelectedCharts = set('selectedCharts');
const setSelectedMetrics = set('selectedMetrics');
const setSelectedTargets = set('selectedTargets');

const doSetQueryTargets = (targets: string[], report: IReport): IReport => {
  const isSingleMetric =
    !!targets.length || (targets.length === 1 && targets[0] === 'genpop');
  if (isSingleMetric) {
    return flow(
      setDimensions(report?.query?.group?.filter((grp) => grp !== 'TARGET')),
      setQueryTargets(targets),
    )(report);
  }
  return setQueryTargets(targets)(report);
};

interface IQueryWithDateLimits {
  mode: Mode;
  minDate: string;
}
export const reportReducer = (
  report: IReport = initialState,
  action: Action<
    | IReport
    | boolean
    | number
    | IReportSorting
    | string
    | string[]
    | IQueryWithDateLimits
    | undefined
    | RuleFilter[]
  >,
): IReport => {
  const { payload, type } = action;
  const businessData = get('businessData', payload);
  const sorting = get('sorting', payload);
  switch (type) {
    case ActionType.REPORT_SET_REPORT:
      return payload as IReport;
    case ActionType.REPORT_SET_REPORT_NAME:
      return setReportName(payload, report);
    case ActionType.REPORT_SET_QUERY_TIME_FRAME:
      return setQueryTimeFrame(payload, report);
    case ActionType.REPORT_SET_NOT_PERSISTED_PROPS:
      return setNotPersistedProps(payload, report);
    case ActionType.REPORT_ADD_NOT_PERSISTED_PROPS:
      if (report?.notPersistedProps) {
        return setNotPersistedProps(
          assign(report?.notPersistedProps, payload),
          report,
        );
      }
      return setNotPersistedProps(payload, report);
    case ActionType.REPORT_SET_QUERY_WEIGHT:
      return setQueryWeight(payload, report);
    case ActionType.REPORT_SET_QUERY_INCLUDE_BREAKDOWN:
      return setQueryIncludeTargetBreakdown(payload, report);
    case ActionType.REPORT_SET_QUERY_LEVEL:
      return setQueryLevel(payload, report);
    case ActionType.REPORT_SET_QUERY_TIME_ZONE:
      return setQueryTimeZone(payload, report);
    case ActionType.REPORT_SET_QUERY_SAMPLE_FACTOR:
      return setQuerySampleFactor(payload, report);
    case ActionType.REPORT_SET_ROW_LIMIT:
      return setRowLimit(payload, report);
    case ActionType.REPORT_SET_SELECTED_CHARTS:
      return setSelectedCharts(payload, report);
    case ActionType.REPORT_SET_SELECTED_METRICS:
      return setSelectedMetrics(payload, report);
    case ActionType.REPORT_SET_SELECTED_TARGETS:
      return setSelectedTargets(payload, report);
    case ActionType.REPORT_SET_RULESET_EVENTS:
      return setReportRulesetEvents(payload, report);
    case ActionType.REPORT_SET_LOADED:
      return setReportLoaded(payload, report);
    case ActionType.REPORT_SET_QUERY_MODE:
      return setQueryModeAndResetDatasets(payload as Mode, report);
    case ActionType.REPORT_RESET_CUSTOM_EXPOSURE_SEGMENT_RULES:
      return resetCustomExposureSegmentRules(report);
    case ActionType.REPORT_SET_CUMULATIVE_GROUP:
      return setCumulativeGroup(payload as string, report);
    case ActionType.REPORT_SET_QUERY_FILTER_START_DATE:
      return setQueryFilterStartDate(payload, report);
    case ActionType.REPORT_SET_QUERY_FILTER_END_DATE:
      return setQueryFilterEndDate(payload, report);
    case ActionType.REPORT_SET_QUERY_FILTER_DATE_OFFSET:
      return setQueryFilterRelativeDateOffset(payload as string, report);
    case ActionType.REPORT_SHOW_CONFIRMATION_DIALOG:
      return setReportConfirmationDialog(payload, report);
    case ActionType.REPORT_SHOW_MODAL_DIALOG:
      return setReportModalDialog(payload, report);
    case ActionType.REPORT_ACCEPT_MODAL_DIALOG:
      return setReportModalDialog(emptyModalDialog, report);

    case ActionType.REPORT_CANCEL_CONFIRMATION_DIALOG:
      return setReportConfirmationDialog(emptyConfirmationDialog, report);
    case ActionType.REPORT_ACCEPT_CONFIRMATION_DIALOG:
      return setReportConfirmationDialog(emptyConfirmationDialog, report);
    case ActionType.REPORT_SET_QUERY_DATASETS:
      return setQueryDatasets(payload, report);
    case ActionType.REPORT_SET_QUERY_DAYPART_DATASET_ID:
      return setQueryDaypartDatasetId(payload, report);
    case ActionType.REPORT_SET_QUERY_CROSSWALK_COMBINATION_ID:
      return setCrosswalkCombinationId(payload, report);
    case ActionType.REPORT_SET_QUERY_GENERIC_EVENTS_DATASETS:
      return setQueryGenericEventsDatasets(payload, report);
    case ActionType.REPORT_SET_INTERVAL:
      return setInterval(payload, report);
    case ActionType.REPORT_SET_REAL_QUERY_MODE:
      return setRealQueryMode(payload, report);
    case ActionType.REPORT_SET_METRICS:
      return setMetrics(payload, report);
    case ActionType.REPORT_SET_DIMENSIONS:
      return setDimensions(payload, report);
    case ActionType.REPORT_SET_SORTING:
      return setReportSorting({ businessData, sorting } as ISetSorting, report);
    case ActionType.REPORT_SET_SORT_ORDER:
      return setSortOrder(payload, report);
    case ActionType.REPORT_SET_SORT_FIELD:
      return setSortField(payload, report);
    case ActionType.REPORT_SET_QUERY_SORTING:
      return setQuerySorting(payload, report);
    case ActionType.REPORT_SET_QUERY_TARGETS:
      return doSetQueryTargets(payload as string[], report);
    case ActionType.REPORT_SET_QUERY_FILTERS:
      return setQueryFilters(payload, report);
    case ActionType.REPORT_SET_QUERY_PERFORMANCE_METRIC_GROUPS:
      return setQueryPerformanceMetricGroups(payload, report);
    case ActionType.REPORT_SET_BREAKOUT_CATEGORIES_FILTERS:
      return setBreakoutCategoriesFilters(payload as RuleFilter[], report);
    case ActionType.REPORT_SET_BREAKOUT_CATEGORIES_START_DATE:
      return setBreakoutCategoriesStartDate(payload, report);
    case ActionType.REPORT_SET_BREAKOUT_CATEGORIES_END_DATE:
      return setBreakoutCategoriesEndDate(payload, report);
    case ActionType.REPORT_SET_TABLE_VISIBLE:
      return setTableVisible(payload, report);
    case ActionType.REPORT_SET_CHART_VISIBLE:
      return setChartVisible(payload, report);
    case ActionType.REPORT_SET_ACCORDION_RESULTS_OPEN:
      return setAccordionResultsOpen(true, report);
    case ActionType.REPORT_SET_ACCORDION_QUERY_OPEN:
      return setAccordionQueryOpen(true, report);
    case ActionType.REPORT_SET_ACCORDION_QUERY_CLOSED:
      return setAccordionQueryOpen(false, report);
    case ActionType.REPORT_SET_ACCORDION_SETTINGS_OPEN:
      return setAccordionSettingsOpen(true, report);
    case ActionType.REPORT_SET_PEFORMANCE_METRIC_GROUPS:
      return setReportPerformanceMetricGroups(payload, report);
    case ActionType.REPORT_SET_CONVERSION_MIN_DATE:
      return setGenericConversionsMinDate(payload, report);
    case ActionType.REPORT_SET_CONVERSION_MAX_DATE:
      return setGenericConversionsMaxDate(payload, report);
    case ActionType.REPORT_SET_CONVERSION_TYPE:
      return setReportConversionType(payload, report);
    case ActionType.REPORT_SET_QUERY_CONVERSION_TYPE:
      return setQueryConversionType(report, payload as string | undefined);
    case ActionType.REPORT_SET_EQ_METRIC:
      return setEqMetric(payload, report);
    case ActionType.REPORT_SET_DYNAMIC_BREAKOUTS:
      return setReportDynamicBreakouts(payload, report);
    case ActionType.REPORT_RESET_DYNAMIC_BREAKOUTS:
      return setReportDynamicBreakouts([], report);
    case ActionType.REPORT_SET_REPORT_SAVED:
      return setBaseReportSaved(true, report);
    case ActionType.REPORT_SET_REPORT_NOT_SAVED:
      return setBaseReportSaved(false, report);
    case ActionType.REPORT_TOGGLE_MODAL:
      return toggleIsModalOpen(payload, report);
    case ActionType.REPORT_SET_IS_REPORT_INVALID:
      return setIsReportInvalid(payload, report);
    case ActionType.REPORT_SET_IS_DOWNLOADING_PRESENTATION:
      return setIsDownloadingPresentation(payload, report);
    case ActionType.REPORT_SET_PERSISTED_REPORT:
      return setPersistedReport(payload, report);
    case ActionType.REPORT_SET_EXPOSURE_FLIGHT_DATE_START:
      return setExposureFlightDateStart(payload as string, report);
    case ActionType.REPORT_SET_EXPOSURE_FLIGHT_DATE_END:
      return setExposureFlightDateEnd(payload as string, report);
    case ActionType.REPORT_SET_EXPOSURE_FLIGHT_DATE_RELATIVE_OFFSET:
      return setExposureFlightDateRelativeOffset(payload as string, report);
    case ActionType.REPORT_SET_OUTCOME_VIEWERSHIP_START_DATE:
      return setOutcomeViewershipDateStart(payload as string, report);
    case ActionType.REPORT_SET_OUTCOME_VIEWERSHIP_END_DATE:
      return setOutcomeViewershipDateEnd(payload as string, report);
    case ActionType.REPORT_SET_OUTCOME_VIEWERSHIP_RELATIVE_DATE_OFFSET:
      return setOutcomeViewershipRelativeDateOffset(payload as string, report);
    case ActionType.REPORT_SET_AGGREGATION_METHOD:
      return setAggregationMethod(payload, report);
    case ActionType.REPORT_SET_ATTRIBUTION_WINDOW:
      return setAttributionWindow(payload, report);
    case ActionType.REPORT_SET_FULLY_LOADED:
      return setBaseReportFullyLoaded(payload, report);
    case ActionType.REPORT_SET_HAS_CHANGED:
      return setReportHasChanged(payload as boolean, report);
    default:
      return report;
  }
};

export const savedReport = (
  report: IReport = initialState,
  action: Action<IReport>,
): IReport => {
  const { payload, type } = action;
  switch (type) {
    case ActionType.REPORT_SET_SAVED_REPORT:
      return setSavedReport(payload, report);
    default:
      return report;
  }
};

export const updatedReport = (
  report: IReport = initialState,
  action: Action<IReport>,
): IReport => {
  const { payload, type } = action;

  switch (type) {
    case ActionType.REPORT_SET_UPDATED_REPORT:
      return payload;

    default:
      return report;
  }
};

export const lastValidReport = (
  report: IReport | null = null,
  action: Action<IReport | null>,
): IReport | null => {
  const { payload, type } = action;

  switch (type) {
    case ActionType.REPORT_SET_LAST_VALID_REPORT:
      return payload;
    case ActionType.REPORT_RESET_LAST_VALID_REPORT:
      return emptyLastValidReport;
    default:
      return report ?? emptyLastValidReport;
  }
};

/**
 * It empties filters values for Creative and Brand
 * when switch between Ads and Custom Ads.
 * @param report
 */
export const setFilterBrandCreativeEmptyForAds = (report: IReport): IReport => {
  const mode = getQueryMode(report);
  const newReport = cloneDeep(report);
  let filtersChildren = getQueryFilters(newReport);
  if (
    [Modes.customAdverts, Modes.adverts, Modes.genericEvents].includes(mode)
  ) {
    if (['report', 'target'].includes(newReport.type ?? '')) {
      filtersChildren.forEach((child: UndigestedFilter) => {
        if (['CREATIVE_LABEL', 'BRAND_LABEL'].includes(child.id ?? '')) {
          child.value = '';
        }
        return child;
      });
    } else if (newReport.type === 'attribution-report') {
      /**
       * Attribution Reports (Imp4ct reports) has filter tree like this:
       * filter: {
       *   children: [
       *     {
       *       id: 'exposures_ads',
       *       ...
       *       children: [ // this level is like a common report
       *       {
       *          id: '',
       *            ...
       *            values: [
       *              {
       *                id: 'FILTER_LABEL_1',
       *                ...
       *              }
       *            ]
       *       }
       *       ]
       *     },
       *     {
       *       id: 'outcomes_brandlift'
       *       [different_structure_from_exposures_ads]
       *       ...
       *     }
       *   ]
       * }
       *
       */
      const cleanedFilters = filtersChildren?.[0]?.children?.map(
        (child: UndigestedFilter) => {
          const childToDelete =
            mode === Modes.genericEvents
              ? ['CREATIVE_LABEL', 'BRAND_LABEL', 'VIEWING_TYPE']
              : ['CREATIVE_LABEL', 'BRAND_LABEL'];
          if (childToDelete.includes(child.id ?? '')) {
            delete child.value;
          }

          return child;
        },
      );

      /**
       * The code above is overwriting children only to exposure_ads node
       * filtersChildren comes form cloned report, so it doesn't represent
       * any danger about reassigning variables
       */
      filtersChildren?.forEach((child: RuleFilter) => {
        if (child.name === 'exposures_ads') {
          child.children = cleanedFilters;
        }
      });
    }
  }
  return filtersChildren;
};
