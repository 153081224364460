import IReport, { reportType } from '../../domains/reports/types';
import { reducePerformanceMetrics } from '../../helpers/visualization';
import {
  difference,
  filter,
  find,
  flow,
  get,
  getOr,
  intersection,
  map,
  pick,
} from 'lodash/fp';
import { createSelector } from 'reselect';
import State from '../../types/state';
import { IOption } from '../../types/textValue';
import { getAvailableTargets } from './businessData';

export const getReport = get('report');

export const getReportName = get('name');

export const getUpdatedReport = get('updatedReport');

export const getSavedReport = get('savedReport');

export const getLastValidReport = get('lastValidReport');

export const getQuery = flow(getReport, get('query'));

const getQueryFromLastValidReport = flow(getLastValidReport, get('query'));

const getType = flow(getReport, get('type'));

export const getCumulativeGroup = flow(
  getReport,
  get('query.cumulative_group'),
);

export const getModeFromQuery = flow(getQuery, get('mode'));

export const getQueryDaypartDatasetId = flow(
  getQuery,
  get('daypart_dataset_id'),
);

const getNotPersistedProps = flow(getReport, get('notPersistedProps'));

export const getConfirmationDialog = flow(
  getNotPersistedProps,
  get('confirmationDialog'),
);

export const getModalDialog = flow(getNotPersistedProps, get('modalDialog'));

export const getReportMaxDate = flow(getNotPersistedProps, get('maxDate'));

export const getReportMinDate = flow(getNotPersistedProps, get('minDate'));

export const getBaseReportNew = flow(
  getNotPersistedProps,
  get('isBaseReportNew'),
);

export const getMetrics = flow(getQuery, get('select'));

export const getIncludeTargetBreakdown = flow(
  getQuery,
  get('includeTargetBreakdown'),
);

export const getModeFromLastValidReport = flow(
  getQueryFromLastValidReport,
  get('mode'),
);

export const getAggregationMethod = flow(getReport, get('aggregation_method'));

export const getAttributionWindow = flow(
  getQuery,
  get('attribution_window.end'),
);

export const getEqMetric = flow(getReport, get('use_equivalized_metrics'));

export const getEqMetricFromLastValidReport = flow(
  getLastValidReport,
  get('use_equivalized_metrics'),
);

export const isReportInvalid = flow(
  getNotPersistedProps,
  get('isReportInvalid'),
);

export const isBaseReportFullyLoaded = flow(
  getNotPersistedProps,
  get('isBaseReportFullyLoaded'),
);

const getComplexOutcomes = flow(getNotPersistedProps, get('complexOutcomes'));

export const getPerformanceMetricGroups = flow(
  getComplexOutcomes,
  get('performanceMetricGroups'),
);

export const getGenericConversionsMinDate = flow(
  getComplexOutcomes,
  get('minDate'),
);

export const getGenericConversionsMaxDate = flow(
  getComplexOutcomes,
  get('maxDate'),
);

export const getPerformanceMetricGroupsFromQuery = flow(
  getQuery,
  get('performance_metric_groups'),
);

export const getConversionType = flow(
  getComplexOutcomes,
  get('conversionType'),
);

export const getInvalidTargetList = (state: State): string[] => {
  const queryTargetList = get('report.query.targets', state);
  const availableTargetIdList = flow(
    get('businessData.availableTargets'),
    map('id'),
  )(state);

  return difference(
    queryTargetList,
    availableTargetIdList,
  ) as unknown as string[];
};

export const getValidTargetList = (state: State): string[] => {
  const queryTargetList = get('report.query.targets', state);
  const availableTargetIdList = flow(
    get('businessData.availableTargets'),
    map('id'),
  )(state);

  return intersection(
    queryTargetList,
    availableTargetIdList,
  ) as unknown as string[];
};

const getTargetsFromLastValidReport = flow(
  getQueryFromLastValidReport,
  get('targets'),
);

const getFiltersFromLastValidReport = flow(
  getQueryFromLastValidReport,
  get('filters'),
);

export const getBrandliftFilterFromLastValidReport = flow(
  getFiltersFromLastValidReport,
  get(['children', 1]),
);

export const getPerformanceMetricGroupsFromLastValidReport = flow(
  getQueryFromLastValidReport,
  get('performance_metric_groups'),
  reducePerformanceMetrics,
);

export const getAttributionReportTargetNames: (state: State) => string[] =
  createSelector(
    getAvailableTargets,
    getTargetsFromLastValidReport,
    (availableTargets = [], reportQueryTargets = []) =>
      availableTargets
        .filter((x: IReport) => reportQueryTargets.includes(x.id))
        .map((x: IReport) => x.name),
  );

export const getAttributionReportTargetObjects: (state: State) => IOption[] =
  createSelector(getAttributionReportTargetNames, (targets) => {
    const targetObjects: IOption[] = [
      {
        id: 0,
        text: 'All US Households',
        value: 'genpop',
        color: '#52A2C9',
        secondaryColor: '#52A2C9',
      },
    ];

    if (targets && targets.length > 0) {
      targetObjects.push({
        id: 1,
        text: targets[0],
        value: 'target',
        color: '#77bd22',
        secondaryColor: '#77bd22',
      });
    }

    return targetObjects;
  });

export const getDimensions = flow(getQuery, get('group'));

export const isAccordionSettingsOpen = flow(
  getNotPersistedProps,
  get('isAccordionSettingsOpen'),
);

export const isAccordionQueryOpen = flow(
  getNotPersistedProps,
  get('isAccordionQueryOpen'),
);

export const isAccordionMethodologyOpen = flow(
  getNotPersistedProps,
  get('isAccordionMethodologyOpen'),
);

export const isAccordionResultsOpen = flow(
  getNotPersistedProps,
  get('isAccordionResultsOpen'),
);

export const isModalOpen = flow(getNotPersistedProps, get('isModalOpen'));

export const isBaseReportRunning = flow(
  getNotPersistedProps,
  get('isBaseReportRunning'),
);

export const getIsDownloadingPresentation = flow(
  getNotPersistedProps,
  get('isDownloadingPresentation'),
);

export const isBaseReportSaving = flow(
  getNotPersistedProps,
  get('isBaseReportSaving'),
);

export const dynamicBreakouts = flow(
  getNotPersistedProps,
  get('dynamicBreakouts'),
);

const getFilters = flow(getQuery, get('filters'));

export const getFiltersChildren = flow(getFilters, get('children'));

export const getBreakoutCategories = flow(getQuery, get('breakout_categories'));

export const getBreakoutCategoriesDates = flow(
  getBreakoutCategories,
  getOr([], 'dates'),
);

export const getBreakoutCategoriesFilters = flow(
  getBreakoutCategories,
  getOr([], 'children'),
);

export const getExperianBreakoutFilters = flow(
  getBreakoutCategoriesFilters,
  filter((filter) => get('type', filter) === 'EXPERIAN_BREAKOUTS'),
);

export const getExperianPIQFilter = flow(
  getBreakoutCategoriesFilters,
  find((filter) => get('type', filter) === 'PLACEIQ'),
);
export const getExperianPIQFilterChildren = flow(
  getExperianPIQFilter,
  getOr([], 'children'),
);

export const getExposureAds = flow(
  getFiltersChildren,
  find({ name: 'exposures_ads' }),
);

export const getOutcomeViewership = flow(
  getFiltersChildren,
  find({ name: 'outcomes_viewership' }),
);

export const getExposureAdsFlightDates = flow(
  getExposureAds,
  get('children'),
  find({ id: 'FLIGHT_DATES' }),
);

export const getOutcomeViewershipDates = flow(
  getOutcomeViewership,
  get('children'),
  find({ id: 'FLIGHT_DATES' }),
);

export const getAttributionReportSubType = flow(getReport, get('subType'));

export const getRowLimit = get('rowLimit');
export const getSelectedCharts = get('selectedCharts');
export const getSelectedMetrics = get('selectedMetrics');
export const getSelectedTargets = get('selectedTargets');

export const isTarget = (state: State): boolean =>
  getType(state) === reportType.target;

export const isNewTarget = (state: State): boolean =>
  isTarget(state) && getBaseReportNew(state);

const pickSettings = pick([
  'time_frame',
  'weight',
  'level',
  'time_zone',
  'sample_factor',
]);

export const getSettings = flow(getQuery, pickSettings);

export const getAttributionReportSegmentsList: (state: State) => IOption[] =
  createSelector([], () => [
    {
      id: 0,
      text: 'All',
      value: 'total_all',
      color: '#0255d2',
    },
    {
      id: 1,
      text: 'Exposed: Converted',
      value: 'exposed_converted',
      color: '#5B990D',
    },
    {
      id: 2,
      text: 'Exposed: Not Converted',
      value: 'exposed_not_converted',
      color: '#abd11d',
    },
    {
      id: 3,
      text: 'Unexposed: Converted',
      value: 'unexposed_converted',
      color: '#2D77A1',
    },
    {
      id: 4,
      text: 'Unexposed: Not Converted',
      value: 'unexposed_not_converted',
      color: '#00bcd8',
    },
    {
      id: 5,
      text: 'Exposed',
      value: 'exposed_all',
      color: '#2f3c47',
    },
    {
      id: 6,
      text: 'Unexposed',
      value: 'unexposed_all',
      color: '#81919F',
    },
  ]);
