import QueryBuilderItem from 'components/QueryBuilder/components/QueryBuilderItem';
import SegmentedControl from 'components/SegmentedControl';
import { isBaseReportSaving } from 'domains/reports/adapters/rulesets';
import Dropdown from 'components/Dropdown';
import { getClass } from 'helpers/components';
import { find } from 'lodash/fp';
import React, { FunctionComponent, useMemo, useCallback } from 'react';
import ITextValue from 'types/textValue';
import { LroiMethodOptions } from './LroiMethodOptions.const';
import { LroiAttributionOptions } from './LroiAttributionOptions.const';
import { IMethodologySectionProps } from './types';

const LOCALE_FIELD_AGGREGATION_METHOD = 'Method';

const DOM_KEY_AGGREGATION_METHOD = 'aggregation-method';

const editReportComponentName = 'edit-attribution-report';

const aggregationMethodClass = getClass(editReportComponentName, {
  concat: [DOM_KEY_AGGREGATION_METHOD],
});

export const MethodologySection: FunctionComponent<IMethodologySectionProps> = (
  props,
) => {
  const {
    report,
    toolbar,
    setAggregationMethodAction,
    aggregationMethod,
    attributionWindow,
    setAttributionWindowAction,
  } = props;
  const { isRunLaunched } = toolbar;

  const isReportRunning = isRunLaunched || isBaseReportSaving(report);

  const handleSetAggregationMethod = useCallback(
    (selected: ITextValue): void =>
      setAggregationMethodAction(selected.value as string),
    [setAggregationMethodAction],
  );

  const handleSetAttributionWindow = useCallback(
    (selected: string): void => setAttributionWindowAction(selected),
    [setAttributionWindowAction],
  );

  const selectedAggregationMethod = useMemo(
    () => find({ value: aggregationMethod }, LroiMethodOptions),
    [aggregationMethod],
  );

  const selectedAttributionWindow = attributionWindow ?? '2592000'; // if no window is selected, default to 30 days

  return (
    <>
      <QueryBuilderItem label={LOCALE_FIELD_AGGREGATION_METHOD}>
        <div className={aggregationMethodClass}>
          <SegmentedControl
            upperCase
            disabled={isReportRunning}
            onSelectComplex={handleSetAggregationMethod}
            options={LroiMethodOptions}
            selected={selectedAggregationMethod?.text}
          />
        </div>
      </QueryBuilderItem>
      <br />
      <QueryBuilderItem label="Attribution Window">
        <Dropdown
          value={selectedAttributionWindow}
          disabled={isReportRunning}
          testId="attribution-window"
          trackingId="attribution-window"
          placeholder="Select Attribution Window"
          options={LroiAttributionOptions}
          onChange={handleSetAttributionWindow}
        />
      </QueryBuilderItem>
    </>
  );
};
